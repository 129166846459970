<template>
  <div>
    <v-tooltip bottom :open-on-focus="false" :open-on-hover="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" icon outlined small>
          <v-icon small>mdi-help</v-icon>
        </v-btn>
      </template>
      <div v-html="this.$slots.default[0].text"></div>
      <div class="d-none"><slot></slot></div>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "HelpTooltip",
};
</script>

<style scoped></style>
