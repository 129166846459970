<template>
  <DashboardLayout :title="pageTitle ? pageTitle : '...'">
    <template v-slot:header-actions>
      <div class="d-flex flex-column">
        <div class="text-body-2 text--secondary">
          {{ $t("computation.created") }}
        </div>
        <!--suppress AllyHtmlVueInspection -->
        <div style="white-space: nowrap">
          {{ computation ? formatDateTime(computation.created_at) : "..." }}
        </div>
      </div>
    </template>
    <template v-slot:breadcrumbs>
      <div class="d-flex justify-space-between mb-3">
        <router-link :to="backLink" class="text-decoration-none text-h6">
          <span class="text-h6 text--primary">←&nbsp;</span>
          <span class="font-weight-regular">{{ $t("backLink") }}</span>
        </router-link>
      </div>
    </template>
    <template>
      <div class="d-flex flex-column align-center justify-center fill-height">
        <div class="mb-8">{{ $t("unarchiving.loaderText") }}</div>
        <v-progress-linear
          indeterminate
          style="max-width: 300px"
        ></v-progress-linear>
      </div>
    </template>
  </DashboardLayout>
</template>

<script>
import { getComputation } from "@/services/computations";
import { formatDateTime } from "@/utils";
import { NotFoundApiError } from "@/errors";

export default {
  name: "UnarchivingItem",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // isLoadingComp: true,
      computation: undefined,
      stopComputationPolling: false,
    };
  },
  created() {
    this.pollComputation();
    //   .finally(() => {
    //   this.isLoadingComp = false;
    // });
  },
  computed: {
    pageTitle() {
      return this.computation?.name;
    },
  },
  methods: {
    pollComputation() {
      if (this.stopComputationPolling) {
        return Promise.resolve();
      } else {
        return getComputation(this.id)
          .then(async (computation) => {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            if (computation.status === 1) {
              return this.$router.push({
                name: "computation",
                params: { id: this.id },
              });
            } else if (computation.status === 2) {
              return this.$router.push({
                name: "archiveItem",
                params: { id: this.id },
              });
            }

            this.computation = computation;
            setTimeout(this.pollComputation, 1500);
          })
          .catch((error) => {
            if (error instanceof NotFoundApiError) {
              return this.$router.push({ name: "computations" });
            }
            setTimeout(this.pollComputation, 5000);
          });
      }
    },
    formatDateTime,
  },
  beforeDestroy() {
    this.stopComputationPolling = true;
  },
};
</script>
