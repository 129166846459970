<template>
  <CircularLoader v-if="isLoading"></CircularLoader>
  <AuthLayout v-else-if="error">
    <template v-slot:header>
      <span class="error--text">{{ error }}</span>
    </template>
    <template v-slot:subheader>{{ $t("error.default") }}</template>
    <DoneButton />
  </AuthLayout>
  <AuthLayout v-else>
    <template v-slot:header>{{ $t("confirmation.headerSuccess") }}</template>
    <template v-slot:subheader>{{ $t("confirmation.success") }}</template>
    <DoneButton />
  </AuthLayout>
</template>

<script>
import { confirm } from "@/services/account";
import {
  getErrorMessageByErrorType,
  rethrowWithoutNotification,
} from "@/errorHandler";
import DoneButton from "@/components/public/DoneButton";

export default {
  name: "ConfirmationPage",
  components: { DoneButton },
  props: ["token"],
  data() {
    return {
      isLoading: true,
      error: "",
    };
  },
  beforeMount() {
    confirm(this.token)
      .catch((error) => {
        this.error = getErrorMessageByErrorType(error);
        rethrowWithoutNotification(error);
      })
      .finally(() => (this.isLoading = false));
  },
};
</script>

<style scoped></style>
