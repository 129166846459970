import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/views/LoginPage";
import RegistrationPage from "@/views/RegistrationPage";
import ConfirmationPage from "@/views/ConfirmationPage";
import PageNotFound from "@/views/PageNotFound";
import TestPage from "@/views/TestPage";
import ComputationsListPage from "@/views/dashboard/computations/ComputationsListPage";
import ComputationPage from "@/views/dashboard/computations/ComputationPage";
import SettingsPage from "@/views/dashboard/computations/SettingsPage";
import ArchiveListPage from "@/views/dashboard/computations/ArchiveListPage";
import ArchiveItemPage from "@/views/dashboard/computations/ArchiveItemPage";
import { $t } from "@/plugins/vue-i18n";
import PasswordResetRequestPage from "@/views/PasswordResetRequestPage";
import store from "@/store";
import PasswordResetPage from "@/views/PasswordResetPage";
import UnarchivingPage from "@/views/dashboard/computations/UnarchivingPage";
import HelpPage from "@/views/HelpPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "dashboard" },
  },
  {
    path: "/auth/account/login",
    redirect: { name: "login" },
  },
  // {
  //   path: "/test",
  //   component: TestPage,
  // },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      title: $t("login.title"),
    },
  },
  {
    path: "/register",
    name: "register",
    component: RegistrationPage,
    meta: {
      title: $t("register.title"),
    },
  },
  {
    path: "/password-reset-request",
    name: "passwordResetRequest",
    component: PasswordResetRequestPage,
    meta: {
      title: $t("resetRequest.title"),
    },
  },
  {
    path: "/auth/password/set",
    name: "passwordReset",
    component: PasswordResetPage,
    meta: {
      title: $t("reset.title"),
    },
  },
  {
    path: "/auth/confirm-email/:token",
    name: "confirm",
    component: ConfirmationPage,
    props: true,
    meta: {
      title: $t("confirmation.title"),
    },
  },
  {
    path: "/dashboard",
    beforeEnter: (to, from, next) => {
      if (store.state.profile) {
        next();
      } else {
        store
          .dispatch("fetchProfile")
          .then(() => next())
          .catch(() => {
            next({ name: "login" });
          });
      }
    },
    component: {
      render(h) {
        return h("router-view");
      },
    },
    children: [
      {
        path: "",
        name: "dashboard",
        redirect: { name: "computations" },
      },
      {
        path: "help",
        name: "help",
        component: HelpPage,
        meta: {
          title: "Help",
        },
      },
      {
        path: "computations",
        name: "computations",
        component: ComputationsListPage,
        meta: {
          title: $t("computations.title"),
        },
      },
      {
        path: "computations/unarchiving/:id",
        name: "unarchiving",
        component: UnarchivingPage,
        props: true,
        meta: {
          title: $t("unarchiving.title"),
        },
      },
      {
        path: "computations/:id",
        name: "computation",
        component: ComputationPage,
        props: true,
        meta: {
          title: $t("computation.title"),
        },
      },
      {
        path: "archive",
        name: "archive",
        component: ArchiveListPage,
        meta: {
          title: $t("archive.title"),
        },
      },
      {
        path: "archive/:id",
        name: "archiveItem",
        component: ArchiveItemPage,
        props: true,
        meta: {
          title: $t("archiveItem.title"),
        },
      },
      {
        path: "settings",
        name: "settings",
        component: SettingsPage,
        meta: {
          title: $t("settings.title"),
        },
      },
    ],
  },
  { path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || "PSS-GEO";
  });
});

export default router;
