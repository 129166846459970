<template>
  <v-app>
    <Notice />
    <v-main>
      <v-container fill-height>
        <v-row>
          <v-col class="d-flex justify-center flex-wrap">
            <img
              class="pss-logo"
              :src="require('../assets/pss-geo.png')"
              style="max-height: 70px; margin: 0 40px"
            />
            <img
              :src="require('../assets/infoss.svg')"
              style="max-height: 70px; margin: 0 40px"
            />
          </v-col>
        </v-row>
        <!--        <div class="d-flex justify-center"></div>-->

        <v-row justify="center">
          <v-col cols="12" sm="9" md="7" lg="5" xl="4">
            <v-card class="pa-4" elevation="0">
              <v-card-title>
                <div class="d-flex flex-column mx-auto text-center">
                  <h1 class="text-h5">
                    <slot name="header"></slot>
                  </h1>
                  <span class="subtitle-1">
                    <slot name="subheader"></slot>
                  </span>
                </div>
              </v-card-title>

              <v-card-text>
                <slot></slot>
              </v-card-text>

              <v-card-actions>
                <slot name="links"></slot>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Notice from "@/components/Notice";
export default {
  name: "AuthLayout",
  components: { Notice },
};
</script>
