<template>
  <v-data-table
    v-bind="$attrs"
    :sort-by.sync="defaultSortBy"
    :sort-desc.sync="defaultSortDesc"
    class="computations-table"
    @click:row="$emit('click:row', $event)"
  >
    <template v-slot:item.created_at="{ item }">
      {{ formatDateTime(item.created_at) }}
    </template>
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <slot></slot>
  </v-data-table>
</template>

<script>
import { formatDateTime } from "@/utils";

export default {
  inheritAttrs: false,
  name: "ComputationsList",

  data() {
    return {
      defaultSortBy: "created_at",
      defaultSortDesc: true,
    };
  },
  methods: {
    formatDateTime,
  },
};
</script>

<style>
.computations-table tbody tr {
  cursor: pointer;
}
</style>
