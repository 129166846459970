import moment from "moment";

export function formatDateTime(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.isValid() ? momentDate.format("DD.MM.YYYY HH:mm") : "–";
}

export function formatDate(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.isValid() ? momentDate.format("DD.MM.YYYY") : "–";
}

export function formatTime(date) {
  const momentDate = moment.utc(date).local();
  return momentDate.isValid() ? momentDate.format("HH:mm") : "–";
}

export function dateComparator(aDate, bDate) {
  const aMomentDate = moment.utc(aDate);
  const bMomentDate = moment.utc(bDate);
  if (aMomentDate.isValid() && bMomentDate.isValid()) {
    if (aMomentDate.isBefore(bMomentDate)) {
      return -1;
    }
    if (aMomentDate.isAfter(bMomentDate)) {
      return 1;
    }
    return 0;
  }

  if (!aMomentDate.isValid() && !bMomentDate.isValid()) {
    return 0;
  }

  if (!aMomentDate.isValid()) {
    return -1;
  }
  return 1;
}

export function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}
