import axios from "axios";
import qs from "qs";
import {
  ApiError,
  NetworkError,
  NotFoundApiError,
  UnauthorizedApiError,
} from "@/errors";
import router from "@/router";

/**
 * create HTTP adapter instance with implementation of Double Submit Cookie (copypaster from OSC)
 * @param refreshTokenEndpoint {string?}
 * @returns {AxiosInstance}
 */
function createHttpAdapter(refreshTokenEndpoint = "/token/refresh/account") {
  let refreshTokenPromise = null;
  function refreshToken() {
    if (refreshTokenPromise !== null) {
      return refreshTokenPromise;
    }
    refreshTokenPromise = new Promise((resolve, reject) => {
      refreshTokenInstance
        .post(refreshTokenEndpoint)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
        .finally(() => {
          refreshTokenPromise = null;
        });
    });
    return refreshTokenPromise;
  }

  function authErrorHandler(originalRequest) {
    return new Promise((resolve, reject) => {
      refreshToken().then(
        () => {
          http(originalRequest).then(
            (response) => {
              resolve(response);
            },
            (error) => {
              reject(error);
            }
          );
        },
        (error) => {
          if (router.currentRoute.name !== "login") {
            router.push({ name: "login" });
          }
          reject(error);
        }
      );
    });
  }

  const refreshTokenInstance = axios.create({
    // withCredentials: true,
    xsrfCookieName: "csrf_refresh_token",
    xsrfHeaderName: "X-CSRF-TOKEN",
  });

  const http = axios.create({
    // timeout: 5000,
    // withCredentials: true,
    xsrfCookieName: "csrf_access_token",
    xsrfHeaderName: "X-CSRF-TOKEN",
    paramsSerializer: (params) =>
      qs.stringify(params, { arrayFormat: "repeat" }),
  });

  if (refreshTokenEndpoint) {
    http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          return authErrorHandler(error.config);
        }
        return Promise.reject(error);
      }
    );
  }

  return http;
}

export const httpClient = createHttpAdapter();

//add error handler interceptor
httpClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      switch (error.response.status) {
        case 401:
          throw new UnauthorizedApiError(error);
        case 404:
          throw new NotFoundApiError(error);
        default:
          throw new ApiError(error);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      throw new NetworkError(error);
    } else {
      // Something happened in setting up the request that triggered an Error
      throw error;
    }
  }
);
