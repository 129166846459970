import Vue from "vue";
import { ApiError, AppError, BaseError, NetworkError } from "@/errors";
import { $t } from "@/plugins/vue-i18n";
import { show } from "@/Notifications";

export function rethrowWithoutNotification(error) {
  if (error instanceof BaseError) {
    error.showNotification = false;
  }
  throw error;
}

export function getErrorMessageByErrorType(error) {
  let errorMsg;

  if (error instanceof ApiError) {
    errorMsg = $t("error.api");
  } else if (error instanceof NetworkError) {
    errorMsg = $t("error.network");
  } else if (error instanceof AppError) {
    errorMsg = error.message;
  } else {
    errorMsg = $t("error.default");
  }
  return errorMsg;
}

export function initErrorHandler() {
  Vue.config.errorHandler = (error) => errorHandler(error);

  window.addEventListener("unhandledrejection", function (event) {
    event.promise.catch((error) => errorHandler(error));
    event.preventDefault();
  });
  // window.onerror = (error) => {
  //   errorHandler(error);
  //   return false;
  // };

  function errorHandler(error) {
    if (
      !(error instanceof BaseError) ||
      (error instanceof BaseError && error.showNotification)
    ) {
      show({
        text: getErrorMessageByErrorType(error),
        type: "error",
      });
    }

    if (
      !(error instanceof BaseError) ||
      (error instanceof BaseError && error.writeToLog)
    ) {
      //todo send to sentry (exclude 401, 403, 404 )
      console.error("Error handler:", error);
    }
  }
}
