import VueI18n from "vue-i18n";
import messages from "@/i18n/messages";
import Vue from "vue";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  messages,
  fallbackLocale: "en",
});

export const $t = function () {
  return i18n.t(...arguments);
};
