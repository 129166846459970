<template>
  <AuthLayout>
    <template v-slot:header>{{ $t("login.header") }}</template>
    <template v-slot:subheader
      >{{ $t("login.newUser") }}
      <router-link :to="{ name: 'register' }">{{
        $t("login.registerLink")
      }}</router-link></template
    >
    <v-form ref="form" @submit.prevent="submit">
      <v-text-field
        v-model.trim="email"
        :label="$t('login.email')"
        :rules="rules.email"
      ></v-text-field>
      <v-text-field
        v-model.trim="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :rules="rules.required"
        :label="$t('login.password')"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
      <SubmitButton :loading="isLoading">
        {{ $t("login.btn.logIn") }}
      </SubmitButton>
    </v-form>
    <div v-if="fullName" class="text-center text-body-1 mt-3">
      <router-link :to="{ name: 'computations' }">
        {{ $t("login.youHaveAlreadyAuthorizedAs", { fullName }) }}
      </router-link>
    </div>
    <template v-slot:links>
      <div class="text-center" style="width: 100%">
        {{ $t("login.forgotPassword") }}
        <router-link :to="{ name: 'passwordResetRequest' }">{{
          $t("login.resetPasswordLink")
        }}</router-link>
      </div>
    </template>
  </AuthLayout>
</template>

<script>
import { login } from "@/services/account";
import { AppError } from "@/errors";
import { mapActions, mapGetters } from "vuex";
import SubmitButton from "@/components/public/SubmitButton";

export default {
  name: "LoginPage",
  components: { SubmitButton },
  data: () => {
    return {
      isLoading: false,
      email: null,
      password: null,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions(["fetchProfile"]),
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      login(this.email, this.password)
        .then((isSuccessful) => {
          if (isSuccessful) {
            this.$router.push({ name: "dashboard" });
          } else {
            throw new AppError("Invalid login or password");
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["fullName"]),
    rules() {
      const required = (v) => !!v || "Field is required";

      return {
        required: [required],
        email: [(v) => /.+@.+/.test(v) || "Please enter a valid email address"],
      };
    },
  },
  created() {
    this.fetchProfile().catch(() => {});
  },
};
</script>

<style scoped lang="scss"></style>
