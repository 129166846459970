<template>
  <AuthLayout v-if="registrationSuccess">
    <template v-slot:header>
      {{ $t("register.registrationRequestSent") }}
    </template>
    <template v-slot:subheader>
      {{ $t("register.yourReceiveEmail") }}
    </template>
    <DoneButton />
  </AuthLayout>
  <AuthLayout v-else>
    <template v-slot:header>
      {{ $t("register.header") }}
    </template>

    <template v-slot:subheader>
      {{ $t("register.haveAnAccount") }}
      <router-link :to="{ name: 'login' }"
        >{{ $t("register.loginLink") }}
      </router-link>
    </template>

    <v-form ref="form" @submit.prevent="submit">
      <v-text-field
        v-model.trim="form.firstname"
        :label="$t('register.firstname')"
        :rules="rules.required"
      ></v-text-field>
      <v-text-field
        v-model.trim="form.lastname"
        :label="$t('register.lastname')"
        :rules="rules.required"
      ></v-text-field>
      <v-text-field
        @input="checkEmail"
        :error-messages="emailErrors"
        v-model.trim="form.email"
        :label="$t('register.email')"
        :rules="rules.email"
      ></v-text-field>
      <vue-tel-input-vuetify
        v-model.trim="form.phone"
        :label="$t('register.phone')"
        :rules="rules.phone"
        @input="updatePhone"
        :error-messages="phoneError"
      ></vue-tel-input-vuetify>
      <v-text-field
        v-model.trim="form.company"
        :label="$t('register.company')"
        :rules="rules.required"
      ></v-text-field>
      <v-combobox
        v-model.trim="form.position"
        :items="positions"
        item-text="term"
        item-value="code"
        return-object
        :label="$t('register.position')"
        :rules="rules.required"
      ></v-combobox>
      <v-text-field
        v-model.trim="form.password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('register.password')"
        @click:append="showPassword = !showPassword"
        @input="confirmPassword = ''"
        :rules="rules.password"
      ></v-text-field>
      <v-text-field
        v-model.trim="confirmPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('register.confirmPassword')"
        @click:append="showPassword = !showPassword"
        :rules="rules.confirmPassword"
      ></v-text-field>
      <SubmitButton :loading="isLoading">{{
        $t("register.btn.register")
      }}</SubmitButton>
    </v-form>
  </AuthLayout>
</template>

<script>
import {
  checkEmailAvailability,
  getPositions,
  register,
} from "@/services/account";
import { rethrowWithoutNotification } from "@/errorHandler";
import SubmitButton from "@/components/public/SubmitButton";
import DoneButton from "@/components/public/DoneButton";

export default {
  name: "RegistrationPage",
  components: { DoneButton, SubmitButton },
  data: () => {
    return {
      isLoading: false,
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        company: "",
        position: null,
      },
      registrationSuccess: undefined,
      emailAvailable: undefined,
      confirmPassword: null,
      showPassword: false,
      positions: [],
      phoneError: "",
    };
  },
  methods: {
    updatePhone(formattedNumber, { number, valid }) {
      if (valid) {
        this.phoneError = "";
        this.phone = number.e164;
      } else {
        this.phoneError = "Please enter a valid phone number";
      }
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.isLoading = true;
      register(this.payload)
        .then(() => {
          this.registrationSuccess = true;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkEmail(email) {
      checkEmailAvailability(email)
        .then((isAvailable) => (this.emailAvailable = isAvailable))
        .catch((error) => rethrowWithoutNotification(error));
    },
  },
  computed: {
    emailErrors() {
      if (this.emailAvailable === undefined || this.emailAvailable === true) {
        return "";
      }
      return "This email address is already in use";
    },
    rules() {
      const required = (v) => !!v || "Field is required";

      return {
        required: [required],
        phone: [required, !this.phoneError || this.phoneError],
        email: [
          (v) => /.+@.+/.test(v) || "Please enter a valid email address",
          !this.emailErrors || this.emailErrors,
        ],
        password: [required],
        confirmPassword: [
          required,
          () => this.form.password !== undefined || "Password must be set",
          () => this.form.password.length >= 1 || "Password must be set",
          (v) => this.form.password === v || "Passwords do not match",
        ],
      };
    },
    payload() {
      const payload = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email,
        phone: this.form.phone,
        company_name: this.form.company,
        password: this.form.password,
      };

      if (
        this.form.position !== null &&
        typeof this.form.position === "object"
      ) {
        payload.position_name = this.form.position.term;
        payload.position_code = this.form.position.code;
      } else {
        payload.position_name = this.form.position;
        payload.position_code = null;
      }
      return payload;
    },
  },
  beforeMount() {
    getPositions().then((list) => {
      this.positions = list;
    });
  },
};
</script>

<style scoped lang="scss"></style>
