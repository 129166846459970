<template>
  <v-dialog v-model="showDialog" max-width="600">
    <v-form ref="form" @submit.prevent="addComputation">
      <v-card>
        <v-card-title>{{ $t("addComputation.header") }}</v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('addComputation.name')"
            v-model.trim="name"
            :rules="rules"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="justify-end py-4">
          <v-btn
            @click="cancel"
            rounded
            large
            elevation="3"
            class="text-red"
            :disabled="isLoading"
            >{{ $t("addComputation.btn.cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            type="submit"
            rounded
            large
            :loading="isLoading"
          >
            {{ $t("addComputation.btn.add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { addComputation } from "@/services/computations";

export default {
  name: "AddComputationDialog",
  props: {
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      rules: [(value) => (value ? true : "Value is required")],
      showDialog: false,
      isLoading: false,
    };
  },
  methods: {
    show() {
      this.showDialog = true;
    },
    cancel() {
      this.resetForm();
    },
    addComputation() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      addComputation(this.name)
        .then(() => {
          this.resetForm();
          this.callback();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetForm() {
      this.name = "";
      this.showDialog = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>
