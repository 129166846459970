<template>
  <UnarchivingItem
    :id="id"
    :back-link="$router.resolve({ name: 'computations' }).href"
  />
</template>

<script>
import UnarchivingItem from "@/components/computation/UnarchivingItem";

export default {
  name: "UnarchivingPage",
  props: ["id"],
  components: { UnarchivingItem },
};
</script>
