<template>
  <v-app style="width: 100%">
    <v-main>
      <div class="d-flex flex-grow-1 align-center justify-center fill-height">
        <HelpTooltip>{{ $t("toolTipExample") }}</HelpTooltip>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import HelpTooltip from "@/components/HelpTooltip";

export default {
  name: "HelpPage",
  components: {
    HelpTooltip,
  },
};
</script>
