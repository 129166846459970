<template>
  <v-form @submit.prevent="changePasswordSubmit" ref="form" class="fill-height">
    <v-card outlined rounded class="fill-height d-flex flex-column">
      <v-card-title>
        {{ $t("settings.changePasswordHeader") }}
      </v-card-title>
      <v-card-text class="fill-height">
        <v-text-field
          v-model.trim="oldPassword"
          :rules="rules.oldPassword"
          :append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showOldPassword ? 'text' : 'password'"
          @click:append="showOldPassword = !showOldPassword"
          :label="$t('settings.oldPassword')"
        ></v-text-field>
        <v-text-field
          v-model.trim="newPassword"
          :rules="rules.newPassword"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          @click:append="showNewPassword = !showNewPassword"
          :label="$t('settings.newPassword')"
        ></v-text-field>
        <v-text-field
          v-model.trim="newPasswordConfirm"
          :rules="rules.newPasswordConfirm"
          :type="showNewPassword ? 'text' : 'password'"
          :label="$t('settings.confirmNewPassword')"
        ></v-text-field>
      </v-card-text>

      <v-card-actions class="mt-auto">
        <v-btn :loading="isLoading" color="primary" type="submit" rounded large
          >{{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { required, sameAs } from "@/validators";
import { changePassword } from "@/services/account";
import * as Notification from "@/Notifications";
import { $t } from "@/plugins/vue-i18n";

export default {
  name: "ChangePasswordForm",
  data() {
    return {
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      showOldPassword: false,
      showNewPassword: false,
      rules: {
        oldPassword: [required],
        newPassword: [required],
        newPasswordConfirm: [
          required,
          (value) => sameAs(value, this.newPassword),
        ],
      },
    };
  },
  methods: {
    changePasswordSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.isLoading = true;
      changePassword(this.oldPassword, this.newPassword)
        .then(() => {
          this.$refs.form.resetValidation();
          this.oldPassword = "";
          this.newPassword = "";
          this.newPasswordConfirm = "";

          Notification.show({
            text: $t("successfulSaving"),
            type: "success",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
