export class BaseError extends Error {
  showNotification = true;
  writeToLog = true;
  constructor(message, cause) {
    super(message, { cause: cause });
    this.axiosError = cause;
  }
}

export class ApiError extends BaseError {
  constructor(error) {
    super(error.message, error);
  }
}
export class UnauthorizedApiError extends ApiError {}
export class NotFoundApiError extends ApiError {}

export class NetworkError extends ApiError {}

export class AppError extends BaseError {
  writeToLog = false;
}
