<template>
  <DashboardLayout :title="$t('computations.header')">
    <template v-slot:header-actions>
      <v-btn
        color="primary"
        @click="$refs.addDialog.show()"
        :fab="$vuetify.breakpoint.xsOnly"
        :large="!$vuetify.breakpoint.xsOnly"
        :small="$vuetify.breakpoint.xsOnly"
        rounded
        elevation="4"
      >
        <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
        <template v-if="$vuetify.breakpoint.smAndUp">{{
          $t("computations.btn.add")
        }}</template>
      </v-btn>
    </template>
    <AddComputationDialog ref="addDialog" :callback="fetchComputations" />
    <ComputationsList
      :loading="isLoading"
      :headers="headers"
      :items="list"
      @click:row="
        (item) => $router.push({ name: 'computation', params: { id: item.id } })
      "
    >
      <template v-slot:item.status="{ item }">
        {{ getStatusText(item.status) }}
      </template>
    </ComputationsList>
  </DashboardLayout>
</template>

<script>
import ComputationsList from "@/components/computation/ComputationsList";
import { getComputations } from "@/services/computations";
import AddComputationDialog from "@/components/computation/AddComputationDialog";
import ComputationsListMixin from "@/views/dashboard/computations/ComputationsListMixin";
import { $t } from "@/plugins/vue-i18n";

export default {
  name: "ComputationsListPage",
  components: { ComputationsList, AddComputationDialog },
  mixins: [ComputationsListMixin],
  myApiService: getComputations,
  computed: {
    headers() {
      return [
        ...this.genericHeaders,
        { text: $t("computations.table.status"), value: "status" },
      ];
    },
  },
  methods: {
    getStatusText(status) {
      let text = "-";
      switch (status) {
        case 1:
          text = $t("computations.table.statusValue.active");
          break;
        case 3:
          text = $t("computations.table.statusValue.unarchiving");
          break;
      }
      return text;
    },
  },
};
</script>
