<template>
  <v-snackbar v-model="snackbar" :color="type" text outlined top timeout="5000">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn text icon x-small @click="snackbar = false" v-bind="attrs">
        <v-icon color="pink">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import EventBus from "@/eventBus";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Notice",
  data() {
    return {
      snackbar: false,
      text: "",
      type: "",
    };
  },
  created() {
    EventBus.$on("showNotice", ({ text, type }) => {
      this.text = text;
      this.type = type;
      this.snackbar = true;
    });
  },
};
</script>

<style scoped></style>
