<template>
  <div class="text-center">
    <v-btn
      large
      elevation="3"
      rounded
      color="primary"
      @click="navigateToLoginPage"
    >
      {{ $t("btn.done") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "DoneButton",
  methods: {
    navigateToLoginPage() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped></style>
