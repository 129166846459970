<template>
  <AuthLayout v-if="success">
    <template v-slot:header>{{ $t("resetRequest.headerSent") }}</template>
    <template v-slot:subheader>
      <span style="word-break: normal">
        {{ $t("resetRequest.checkEmail") }}
      </span>
    </template>
    <DoneButton />
  </AuthLayout>
  <AuthLayout v-else>
    <template v-slot:header>{{ $t("resetRequest.header") }}</template>
    <template v-slot:subheader>
      <span style="word-break: normal">
        {{ $t("resetRequest.enterYourEmail") }}
      </span>
    </template>
    <v-form ref="form" @submit.prevent="submit">
      <v-text-field
        v-model.trim="email"
        :label="$t('resetRequest.email')"
        :rules="rules.email"
      ></v-text-field>
      <SubmitButton :loading="isLoading">{{
        $t("resetRequest.btn.send")
      }}</SubmitButton>
    </v-form>
    <template v-slot:links>
      <router-link :to="{ name: 'login' }">{{
        $t("resetRequest.loginLink")
      }}</router-link>
    </template>
  </AuthLayout>
</template>

<script>
import { resetPasswordRequest } from "@/services/account";
import SubmitButton from "@/components/public/SubmitButton";
import DoneButton from "@/components/public/DoneButton";

export default {
  name: "PasswordResetRequestPage",
  components: { SubmitButton, DoneButton },
  data: () => {
    return {
      isLoading: false,
      email: null,
      success: undefined,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        resetPasswordRequest(this.email)
          .then(() => {
            this.success = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    rules() {
      const required = (v) => !!v || "Field is required";

      return {
        required: [required],
        email: [(v) => /.+@.+/.test(v) || "Please enter a valid email address"],
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
