<template>
  <v-btn
    class="mt-3"
    outlined
    color="primary"
    rounded
    large
    block
    elevation="3"
    type="submit"
    v-bind="$attrs"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: "SubmitButton",
};
</script>

<style scoped></style>
