<template>
  <v-btn
    :disabled="$attrs.disabled"
    block
    outlined
    color="primary"
    @click="callback"
    class="mb-6"
    elevation="3"
    rounded
    large
  >
    {{ label }}
  </v-btn>
</template>

<script>
export default {
  name: "StageButton",
  props: ["label", "callback"],
};
</script>

<style scoped></style>
