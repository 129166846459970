<template>
  <div>test</div>
</template>

<script>
export default {
  name: "TestPage",
};
</script>

<style scoped></style>
