<template>
  <div>
    <div class="d-flex">
      <div v-if="tracesAmount">
        {{ $t("computation.tracesToLoad") }}: {{ tracesAmount }}
      </div>
      <div v-if="state && state.is_running && timeElapsed" class="ml-auto">
        {{ $t("computation.timeElapsed") }}: {{ timeElapsed }}
      </div>
    </div>
    <div class="d-flex align-center">
      <v-progress-linear
        v-if="loading"
        height="25"
        :indeterminate="!progress"
        color="primary"
        :value="progress"
        rounded
      >
        <!--suppress AllyHtmlVueInspection -->
        <span v-if="progress" class="text-h5">{{ progress }}%</span>
      </v-progress-linear>
      <ConfirmationDialog v-if="computationId" :callback="cancelComputation">
        <template v-slot:activator="{ on }">
          <v-btn
            :disabled="!isCancelable"
            icon
            text
            class="ml-3"
            @click="showDialog = true"
            v-on="on"
          >
            <v-icon color="pink">mdi-close</v-icon>
          </v-btn>
        </template>
      </ConfirmationDialog>
    </div>
  </div>
</template>

<script>
import { $t } from "@/plugins/vue-i18n";
import { cancelComputation } from "@/services/computations";
import moment from "moment";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  name: "LinearLoader",
  components: { ConfirmationDialog },
  props: {
    state: {
      type: Object,
      required: false,
    },
    computationId: {
      type: [Number, String],
      required: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => ({
        progress: 0,
        info: {},
      }),
    },
  },
  computed: {
    timeElapsed() {
      const startTime = this.state?.start_time;
      if (!startTime) {
        return;
      }

      const diffMs = moment().diff(moment.utc(startTime));
      const duration = moment.duration(diffMs);
      // noinspection UnnecessaryLocalVariableJS
      const diffFormatted =
        Math.floor(duration.asHours()) + moment.utc(diffMs).format(":mm:ss");

      return diffFormatted;
    },

    isCancelable() {
      return !!this.state?.jobs?.length;
    },
    progress() {
      return this.value?.progress;
    },
    tracesAmount() {
      const items = [];
      function addItem(value, translation) {
        if (value) {
          items.push(`${value} (${translation})`);
        }
      }

      addItem(
        this.value?.info?.traces_amount_segy_density,
        $t("computation.tracesAmount.density")
      );
      addItem(
        this.value?.info?.traces_amount_segy_p_wave,
        $t("computation.tracesAmount.pWave")
      );
      addItem(
        this.value?.info?.traces_amount_segy_porosity,
        $t("computation.tracesAmount.porosity")
      );

      return items.join(", ");
    },
  },
  methods: {
    cancelComputation() {
      return cancelComputation(this.computationId);
    },
  },
};
</script>

<style scoped></style>
