<template>
  <AuthLayout v-if="success">
    <template v-slot:header>{{ $t("reset.headerSuccess") }}</template>
    <template v-slot:subheader>
      <span style="word-break: normal">
        {{ $t("reset.successfullyReseted") }}
      </span>
    </template>
    <DoneButton />
  </AuthLayout>
  <AuthLayout v-else>
    <template v-slot:header>{{ $t("reset.header") }}</template>
    <v-form ref="form" @submit.prevent="submit">
      <v-text-field
        v-model.trim="password"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('reset.newPassword')"
        @click:append="showPassword = !showPassword"
        @input="confirmPassword = ''"
        :rules="rules.password"
      ></v-text-field>
      <v-text-field
        v-model.trim="confirmPassword"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('reset.confirmNewPassword')"
        @click:append="showPassword = !showPassword"
        :rules="rules.confirmPassword"
      ></v-text-field>
      <SubmitButton :loading="isLoading">{{
        $t("reset.btn.submit")
      }}</SubmitButton>
    </v-form>
    <template v-slot:links>
      <router-link :to="{ name: 'login' }">{{
        $t("reset.loginLink")
      }}</router-link>
    </template>
  </AuthLayout>
</template>

<script>
import { resetPassword } from "@/services/account";
import SubmitButton from "@/components/public/SubmitButton";
import DoneButton from "@/components/public/DoneButton";

export default {
  name: "PasswordResetPage",
  components: { SubmitButton, DoneButton },
  created() {
    this.token = this.$router.currentRoute.query.token;
    if (!this.token) {
      this.$router.push({ name: "login" });
    }
  },
  data: () => {
    return {
      isLoading: false,
      token: undefined,
      success: undefined,
      password: "",
      confirmPassword: null,
      showPassword: false,
    };
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        resetPassword(this.password, this.token)
          .then(() => {
            this.success = true;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    rules() {
      const required = (v) => !!v || "Field is required";

      return {
        required: [required],
        password: [required],
        confirmPassword: [
          required,
          () => this.password !== undefined || "Password must be set",
          () => this.password.length >= 1 || "Password must be set",
          (v) => this.password === v || "Passwords do not match",
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
