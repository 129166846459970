import { dateComparator } from "@/utils";
import { $t } from "@/plugins/vue-i18n";
import { v4 as uuidv4 } from "uuid";

export default {
  created() {
    this.fetchComputations();
    this.connectWS(true);
  },
  data() {
    return {
      ws: undefined,
      list: [],
      stopListPolling: false,
      stopWSConnectRetrying: false,
      isLoading: true,
      genericHeaders: [
        { text: $t("computations.table.id"), value: "id" },
        { text: $t("computations.table.name"), value: "name" },
        {
          text: $t("computations.table.createdAt"),
          value: "created_at",
          sort: dateComparator,
        },
      ],
    };
  },
  myApiService: undefined, //required and should be provided from component
  computed: {
    headers() {
      return [...this.genericHeaders];
    },
  },
  methods: {
    async connectWS(startPolling) {
      if (this.stopWSConnectRetrying) {
        return;
      }
      try {
        this.ws = await this.setupWS();
        // console.log("ws connected", this.ws);

        //stop polling
        this.stopListPolling = true;

        //start checking ws state
        this.checkWSState(this.ws);
      } catch (error) {
        // console.log("error connection", error);

        if (startPolling) {
          this.pollList();
        }

        setTimeout(() => this.connectWS(false), 2000);
      }
    },
    checkWSState(ws) {
      // console.log("checking ws state:", ws.readyState);
      if ([0, 1].includes(ws.readyState)) {
        setTimeout(() => this.checkWSState(ws), 1000);
      } else {
        this.connectWS(true);
      }
    },
    setupWS() {
      return new Promise((resolve, reject) => {
        const ws = new WebSocket(process.env.VUE_APP_WS_URL);
        // const ws = new WebSocket("ws://pss-geo.local:8081/gateway/wsd");
        ws.onerror = (error) => reject(error);
        // ws.onclose = () => {
        //   re
        // }

        //timeout in case if no response to subscription is become
        setTimeout(() => {
          reject();
        }, 6000);

        const subscriptionId = uuidv4().toString();
        ws.onopen = () => {
          ws.send(
            JSON.stringify({
              subscribe: { type: "computations_list" },
              subscription_id: subscriptionId,
            })
          );
        };

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          // console.log("event", event);
          if (data.subscription) {
            if (data.subscription_id === subscriptionId) {
              if (data.subscribed) {
                // console.log("successfully subscribed!", data);
                resolve(ws);
              } else {
                // console.log("subscription failed", event);
                reject(event);
              }
            }
          } else if (data.event === "computations_list_changed") {
            // console.log(
            //   "computations_list_changed event - call fetchComputations"
            // );
            this.fetchComputations();
          }
        };
      });
    },
    fetchComputations() {
      this.isLoading = true;
      return this.$options
        .myApiService()
        .then((list) => {
          this.list = list;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    pollList() {
      if (this.stopListPolling) {
        return Promise.resolve();
      } else {
        // console.log("polling");
        return this.fetchComputations()
          .then(() => {
            setTimeout(this.pollList, 10000);
          })
          .catch(() => {
            setTimeout(this.pollList, 10000);
          });
      }
    },
  },
  beforeDestroy() {
    this.stopListPolling = true;
    this.stopWSConnectRetrying = true;
    this.ws && this.ws.close();
  },
};
