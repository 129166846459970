import { httpClient } from "@/services/http";
import { ApiError, AppError } from "@/errors";
import { $t } from "@/plugins/vue-i18n";

export function getComputations() {
  return httpClient
    .get("/gateway/geo/api/v1/computations/list")
    .then((response) => {
      return response.data;
    });
}

export function getArchiveComputations() {
  return httpClient
    .get("/gateway/geo/api/v1/computations/list-archived")
    .then((response) => {
      return response.data;
    });
}

export function addComputation(name) {
  return httpClient
    .post("/gateway/geo/api/v1/computations/add", { name })
    .catch((error) => {
      if (error instanceof ApiError) {
        throw new AppError("Failed adding computation");
      }
    });
}

/*
 status = 1/2/3 (active/archived/unarchiving)
 */
export function getComputation(id) {
  return httpClient
    .get(`/gateway/geo/api/v1/computations/${id}`)
    .then((response) => {
      return response.data;
    });
}

export function getComputationFiles(id) {
  return httpClient
    .get(`/gateway/geo/api/v1/computations/${id}/list-files`)
    .then((response) => {
      return response.data;
    });
}

export function validateComputation(id) {
  return httpClient
    .post(`/gateway/geo/api/v1/computations/${id}/validate`)
    .then((response) => {
      return response.data;
    })
    .catch(handleStageActionError);
}

export function previewComputation(id) {
  return httpClient
    .post(`/gateway/geo/api/v1/computations/${id}/preview`)
    .then((response) => {
      return response.data;
    })
    .catch(handleStageActionError);
}
export function executeComputation(id) {
  return httpClient
    .post(`/gateway/geo/api/v1/computations/${id}/execute`)
    .then((response) => {
      return response.data;
    })
    .catch(handleStageActionError);
}

export function getComputationState(id) {
  return httpClient
    .get(`/gateway/geo/api/v1/computations/${id}/state`)
    .then((response) => {
      return response.data;
    });
}

export function getProgress(id) {
  return httpClient
    .get(`/gateway/geo/api/v1/computations/${id}/progress`)
    .then((response) => {
      return response.data;
    });
}

export function getComputationImageUrl(id, imageUid) {
  return `/gateway/geo/api/v1/computations/${id}/state/image?uid=${imageUid}`;
}

export function getComputationTable(id, tableUid) {
  return httpClient
    .get(`/gateway/geo/api/v1/computations/${id}/state/table`, {
      params: { uid: tableUid },
    })
    .then((response) => {
      return response.data;
    });
}

export function cancelComputation(id) {
  return httpClient
    .post(`/gateway/geo/api/v1/computations/${id}/cancel`)
    .then((response) => {
      return response.data;
    });
}

export function deleteComputation(id) {
  return httpClient
    .post(`/gateway/geo/api/v1/computations/${id}/delete`)
    .then((response) => {
      return response.data;
    });
}

export function unarchiveComputation(id) {
  return httpClient
    .post(`/gateway/geo/api/v1/computations/${id}/unarchive`)
    .then((response) => {
      return response.data;
    });
}

function handleStageActionError(error) {
  if (error instanceof ApiError) {
    if (error.axiosError.response.status === 400) {
      throw new AppError($t("error.actionNotAllowed"));
    } else if (error.axiosError.response.status !== 409) {
      throw error;
    }
  } else {
    throw error;
  }
}
