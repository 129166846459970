<template>
  <div>
    <v-data-table
      v-if="table"
      :headers="headers"
      :items="items"
      class="mb-6"
    ></v-data-table>
    <div v-if="image" class="text-center">
      <img :src="image" alt="" style="max-width: 100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ComputationResult",
  props: {
    image: {
      type: String,
    },
    table: {
      type: Object,
    },
  },
  computed: {
    headers() {
      return this.table
        ? this.table.columns.map((col) => ({
            text: col,
            value: col || "-",
          }))
        : [];
    },
    items() {
      return this.table && this.headers
        ? this.table.data.map((item) =>
            item.reduce((result, val, index) => {
              result[this.headers[index]?.value || "-"] = val;
              return result;
            }, {})
          )
        : [];
    },
  },
};
</script>

<style scoped></style>
