import Vue from "vue";
import Vuex from "vuex";
import { getProfile } from "@/services/account";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    profile: undefined,
  },
  getters: {
    fullName(state) {
      return (
        state.profile && `${state.profile.firstname} ${state.profile.lastname}`
      );
    },
  },
  mutations: {
    SET_PROFILE(state, profile) {
      state.profile = profile;
    },
  },
  actions: {
    fetchProfile({ commit }) {
      return getProfile()
        .then((profile) => commit("SET_PROFILE", profile))
        .catch((e) => {
          commit("SET_PROFILE", undefined);
          throw e;
        });
    },
  },
  modules: {},
});
