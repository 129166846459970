<template>
  <DashboardLayout :title="$t('settings.header')">
    <v-row>
      <v-col cols="12" md="6">
        <EditProfileForm />
      </v-col>
      <v-col cols="12" md="6">
        <ChangePasswordForm />
      </v-col>
    </v-row>
  </DashboardLayout>
</template>

<script>
import ChangePasswordForm from "@/components/settings/ChangePasswordForm";
import EditProfileForm from "@/components/settings/EditProfileForm";

export default {
  name: "SettingsPage",
  components: { ChangePasswordForm, EditProfileForm },
};
</script>

<style scoped></style>
