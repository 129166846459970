<template>
  <v-dialog v-model="showDialog" max-width="400" :persistent="isLoading">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"> </slot>
    </template>
    <v-card class="pa-4">
      <v-card-title class="justify-center">
        <span class="headline">{{
          $t("computation.confirmCancelDialog.header")
        }}</span>
      </v-card-title>
      <v-card-actions class="justify-space-around py-4">
        <v-btn
          :disabled="isLoading"
          @click="showDialog = false"
          rounded
          large
          elevation="3"
        >
          {{ $t("computation.confirmCancelDialog.btn.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          type="submit"
          rounded
          large
          @click="submit"
          :loading="isLoading"
        >
          {{ $t("computation.confirmCancelDialog.btn.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",
  inheritAttrs: false,
  props: {
    callback: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      showDialog: false,
    };
  },
  methods: {
    submit() {
      this.isLoading = true;
      this.callback().finally(() => {
        this.isLoading = false;
        this.showDialog = false;
      });
    },
  },
};
</script>

<style scoped></style>
