export default {
  login: {
    title: "Log in or sign up",
    header: "Login",
    email: "Email address",
    password: "Password",
    btn: {
      logIn: "Login",
    },
    newUser: "New user?",
    registerLink: "Register",
    forgotPassword: "Forgot password?",
    resetPasswordLink: "Reset password",
    youHaveAlreadyAuthorizedAs: "You have already authorized as {fullName}",
  },
  register: {
    title: "Create account",
    header: "Register",
    registrationRequestSent: "Registration request sent!",
    yourReceiveEmail:
      "You will receive an email confirming your registration request",
    btn: {
      register: "Register",
    },
    firstname: "Your first name",
    lastname: "Your last name",
    email: "Email address",
    phone: "Phone number",
    company: "Company name",
    position: "Position",
    password: "Password",
    confirmPassword: "Confirm password",
    failed: "Registration failed",
    haveAnAccount: "Already have an account?",
    loginLink: "Login",
  },
  confirmation: {
    title: "Confirmation",
    success:
      "Your registration request successfully confirmed. Please follow the link below to log in.",
    loginLink: "Login",
    failed: "Failed to confirm email address",
    headerSuccess: "Congratulations",
  },
  computations: {
    title: "Dashboard - Computations",
    btn: {
      add: "New computation",
    },
    header: "Active computations",
    table: {
      id: "Id",
      name: "Name",
      createdAt: "Created At",
      status: "Status",
      statusValue: {
        active: "Active",
        unarchiving: "Unarchiving",
      },
    },
  },
  computation: {
    title: "Dashboard - Active Computations",
    uploadedFilesSection: "1. Uploaded files",
    needFiles: "Please upload files first",
    validationOutdated:
      "Some files were uploaded after last validation! New validation is needed",
    btn: {
      validate: "Validate files",
      preview: "Click to start preview",
      execute: "Click to start computation",
    },
    previewSection: "2. Preview",
    executeSection: "3. Execute",
    created: "Created",
    tracesToLoad: "Traces to load",
    tracesAmount: {
      density: "density",
      pWave: "p_wave",
      porosity: "porosity",
    },
    timeElapsed: "Time elapsed",
    tracesLoaded: "Traces loaded",
    confirmCancelDialog: {
      header: "Are you sure?",
      btn: {
        cancel: "Cancel",
        confirm: "Confirm",
      },
    },
    unarchive: "Unarchive",
    delete: "Delete",
  },
  archive: {
    title: "Dashboard - Archive",
    header: "Archive",
  },
  archiveItem: {
    title: "Dashboard - Archive",
  },
  error: {
    api: "Something went wrong during request, please try again later",
    network: "There were network issues, please try again later",
    default: "Something went wrong, please try again later",
    ooops: "Ooops....",
    pageNotFound: "Page not found",
    actionNotAllowed: "This action is not allowed at this moment",
  },
  addComputation: {
    header: "New computation",
    name: "Name",
    btn: {
      cancel: "Cancel",
      add: "Add new",
    },
  },
  menu: {
    computations: "Computations",
    archive: "Archive",
    logout: "Logout",
  },
  backLink: "Back",
  resetRequest: {
    loginLink: "Login",
    btn: {
      send: "Send",
    },
    header: "Reset password",
    enterYourEmail:
      "Enter your email address & we''ll send you a link to reset your password.",
    email: "Email address",
    headerSent: "Sent! Check Your Email",
    checkEmail:
      "Head over to your mailbox to get your reset link and create your brand new password.",
    title: "Reset password",
  },
  reset: {
    title: "Reset password",
    header: "Enter your new password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    btn: {
      submit: "Set new password",
    },
    loginLink: "Login",
    headerSuccess: "Congratulations",
    successfullyReseted: "Your password has been changed successfully.",
  },
  btn: {
    done: "Done",
    save: "Save",
  },
  settings: {
    title: "Dashboard - Settings",
    header: "Settings",
    name: "Name",
    company: "Company",
    changePasswordHeader: "Change password",
    profileHeader: "Profile",
    oldPassword: "Old password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    position: "Position",
    phone: "Phone",
    email: "Email",
  },
  validation: {
    required: "Field is required",
    sameAs: "Values must match",
  },
  successfulSaving: "Saved successfully",
  unarchiving: {
    title: "Dashboard - Unarchiving computation",
    loaderText: "The computation is unarchiving",
  },
  toolTipExample:
    'In <span style="color: pink; font-weight: bold">progress...</span>',
};
