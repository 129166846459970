import { ApiError, AppError, NotFoundApiError } from "@/errors";
import { httpClient } from "@/services/http";
import { $t } from "@/plugins/vue-i18n";

export function changePassword(oldPassword, newPassword) {
  return httpClient.post("/gateway/geo/api/v1/auth/set-password", {
    old_password: oldPassword,
    new_password: newPassword,
  });
}

export function resetPassword(password, token) {
  return httpClient.post("/gateway/core/token/auth/account/password/set", {
    password,
    token,
  });
}

export function resetPasswordRequest(email) {
  return httpClient.post("/gateway/core/token/auth/account/password/reset", {
    login: email,
  });
}

export function logout() {
  return httpClient.get("/token/remove").then((response) => {
    return response;
  });
}

export function login(email, password) {
  return httpClient
    .post("/token/account", {
      username: email,
      password,
    })
    .then((response) => {
      return response.data.login;
    });
}

export function checkEmailAvailability(email) {
  return httpClient
    .post("/gateway/registration/api/v1/registration/check-email-available", {
      email,
    })
    .then((response) => {
      return response.data.is_available === true;
    });
}

export function getPositions() {
  return httpClient.get("/gateway/core/api/dic/3?lang=1").then((response) => {
    return response.data;
  });
}

export function register(fields) {
  return httpClient
    .post("/gateway/geo/api/v1/auth/register", fields)
    .catch((error) => {
      if (error instanceof ApiError) {
        throw new AppError($t("register.failed"));
      }
    });
}

export function confirm(token) {
  return httpClient
    .post("/gateway/geo/api/v1/auth/email/confirm/", {
      token,
    })
    .catch((error) => {
      if (error instanceof NotFoundApiError) {
        throw new AppError($t("confirmation.failed"));
      } else {
        throw error;
      }
    });
}

export function getProfile() {
  return httpClient
    .get("/gateway/geo/api/v1/auth/me")
    .then((response) => response.data);
}

export function updateProfile(fields) {
  return httpClient.post("/gateway/geo/api/v1/auth/me", fields);
}
