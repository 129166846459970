<template>
  <ComputationItem
    :id="id"
    :back-link="$router.resolve({ name: 'archive' }).href"
  />
</template>

<script>
import ComputationItem from "@/components/computation/ComputationItem";

export default {
  name: "ArchiveItemPage",
  props: ["id"],
  components: { ComputationItem },
};
</script>
