import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import { initErrorHandler } from "@/errorHandler";
import AuthLayout from "@/components/AuthLayout";
import DashboardLayout from "@/components/DashboardLayout";
import CircularLoader from "@/components/CircularLoader";
import LinearLoader from "@/components/LinearLoader";
import { i18n } from "@/plugins/vue-i18n";

Vue.component("AuthLayout", AuthLayout);
Vue.component("DashboardLayout", DashboardLayout);
Vue.component("CircularLoader", CircularLoader);
Vue.component("LinearLoader", LinearLoader);

initErrorHandler();
Vue.config.productionTip = false;
Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  store,
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
