<template>
  <v-overlay v-if="loading">
    <v-progress-circular
      indeterminate
      color="primary"
      size="64"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "CircularLoader",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped></style>
