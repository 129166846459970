<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-card outlined rounded class="fill-height d-flex flex-column">
      <v-card-title>
        {{ $t("settings.profileHeader") }}
      </v-card-title>

      <v-card-text class="fill-height">
        <v-text-field
          v-model.trim="form.firstname"
          :label="$t('register.firstname')"
          :rules="rules.required"
        ></v-text-field>
        <v-text-field
          v-model.trim="form.lastname"
          :label="$t('register.lastname')"
          :rules="rules.required"
        ></v-text-field>
        <v-text-field
          @input="checkEmail"
          :error-messages="emailErrors"
          v-model.trim="form.email"
          :label="$t('register.email')"
          :rules="rules.email"
        ></v-text-field>
        <vue-tel-input-vuetify
          v-model.trim="form.phone"
          :label="$t('register.phone')"
          :rules="rules.phone"
          @input="updatePhone"
          :error-messages="phoneError"
        ></vue-tel-input-vuetify>
        <v-combobox
          v-model.trim="form.position"
          :items="positions"
          item-text="term"
          item-value="code"
          :label="$t('register.position')"
          :rules="rules.required"
        ></v-combobox>
      </v-card-text>

      <v-card-actions class="mt-auto">
        <v-btn :loading="isLoading" color="primary" type="submit" rounded large
          >{{ $t("btn.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {
  checkEmailAvailability,
  getPositions,
  updateProfile,
} from "@/services/account";
import { rethrowWithoutNotification } from "@/errorHandler";
import * as Notification from "@/Notifications";
import { $t } from "@/plugins/vue-i18n";
import { mapActions } from "vuex";

export default {
  name: "EditProfileForm",
  data: () => {
    return {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        position: null,
      },
      isLoading: false,
      emailAvailable: undefined,
      positions: [],
      phoneError: "",
    };
  },
  methods: {
    updatePhone(formattedNumber, { number, valid }) {
      if (valid) {
        this.phoneError = "";
        this.phone = number.e164;
      } else {
        this.phoneError = "Please enter a valid phone number";
      }
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      this.isLoading = true;
      updateProfile(this.payload)
        .then(() => {
          Notification.show({
            text: $t("successfulSaving"),
            type: "success",
          });
          this.fetchProfile();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    checkEmail(email) {
      checkEmailAvailability(email)
        .then((isAvailable) => (this.emailAvailable = isAvailable))
        .catch((error) => rethrowWithoutNotification(error));
    },
    ...mapActions(["fetchProfile"]),
  },
  computed: {
    emailErrors() {
      if (this.emailAvailable === undefined || this.emailAvailable === true) {
        return "";
      }
      return "This email address is already in use";
    },
    rules() {
      const required = (v) => !!v || "Field is required";

      return {
        required: [required],
        phone: [required, !this.phoneError || this.phoneError],
        email: [
          (v) => /.+@.+/.test(v) || "Please enter a valid email address",
          !this.emailErrors || this.emailErrors,
        ],
      };
    },
    payload() {
      const payload = {
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        email: this.form.email,
        phone: this.form.phone,
      };

      if (
        this.form.position !== null &&
        typeof this.form.position === "object"
      ) {
        payload.position_name = this.form.position.term;
        payload.position_code = this.form.position.code;
      } else {
        payload.position_name = this.form.position;
        payload.position_code = null;
      }

      return payload;
    },
  },
  beforeMount() {
    const profile = this.$store.state.profile;
    this.form.firstname = profile.firstname;
    this.form.lastname = profile.lastname;
    this.form.email = profile.email;
    this.form.phone = profile.phone;
    this.form.position = {
      term: profile.position_name,
      code: profile.position_code,
    };

    getPositions().then((list) => {
      this.positions = list;
    });
  },
};
</script>
