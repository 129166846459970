<template>
  <v-app>
    <Notice />
    <v-app-bar app v-model="showAppBar" color="#333563" dark>
      <v-app-bar-nav-icon
        @click="showNavDrawer = !showNavDrawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ $store.state.profile.company_name }}</v-toolbar-title>
      <div class="ml-auto text-truncate">{{ fullName }}</div>
    </v-app-bar>
    <v-navigation-drawer v-model="showNavDrawer" app color="#333563" dark>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ $store.state.profile.company_name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ fullName }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list nav>
        <v-list-item v-for="item in menu" :key="item.title" :to="item.route">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-list>
          <ConfirmationDialog :callback="logout">
            <template v-slot:activator="{ on }">
              <v-list-item link v-on="on">
                <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ $t("menu.logout") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </ConfirmationDialog>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main app style="background-color: #f7f8fc">
      <v-container fluid class="d-flex flex-column pa-4" style="height: 100%">
        <!--        <v-btn-->
        <!--          class="ma-1"-->
        <!--          fab-->
        <!--          dark-->
        <!--          color="primary"-->
        <!--          fixed-->
        <!--          right-->
        <!--          bottom-->
        <!--          small-->
        <!--          @click="goHelp"-->
        <!--        >-->
        <!--          <v-icon dark> mdi-help</v-icon>-->
        <!--        </v-btn>-->
        <CircularLoader v-if="loading" :loading="loading" />
        <template v-else>
          <div class="d-flex justify-space-between align-center mb-4">
            <h1 class="text-h4 text-truncate">{{ title }}</h1>
            <slot name="header-actions"></slot>
          </div>
          <slot name="breadcrumbs"></slot>
          <!--          <div-->
          <!--            v-if="error"-->
          <!--            class="fill-height d-flex flex-column align-center justify-center"-->
          <!--          >-->
          <!--            <h1 class="text-h5">{{ $t("error.ooops") }}</h1>-->
          <!--            &lt;!&ndash;suppress AllyHtmlVueInspection &ndash;&gt;-->
          <!--            <span class="text-subtitle-1">{{-->
          <!--              error === true ? $t("error.default") : error-->
          <!--            }}</span>-->
          <!--          </div>-->
          <!--          <v-sheet v-else class="pa-6 flex-grow-1" rounded elevation="2">-->
          <v-sheet class="pa-6 flex-grow-1" rounded elevation="2">
            <slot></slot>
          </v-sheet>
        </template>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { logout } from "@/services/account";
import Notice from "@/components/Notice";
import { mapGetters } from "vuex";
import ConfirmationDialog from "@/components/ConfirmationDialog";

export default {
  name: "DashboardLayout",
  components: { Notice, ConfirmationDialog },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    // error: {
    //   type: [String, Boolean],
    // },
  },
  data() {
    return {
      showNavDrawer: !this.$vuetify.breakpoint.mobile,
      menu: [
        {
          title: this.$t("menu.computations"),
          icon: "mdi-calculator",
          route: { name: "computations" },
        },
        {
          title: this.$t("menu.archive"),
          icon: "mdi-package-down",
          route: { name: "archive" },
        },
        {
          title: "Settings",
          icon: "mdi-cog",
          route: { name: "settings" },
        },
      ],
    };
  },
  computed: {
    showAppBar() {
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapGetters(["fullName"]),
  },
  methods: {
    goHelp() {
      let routeData = this.$router.resolve({
        name: "help",
      });
      window.open(routeData.href, "_blank");
    },
    logout() {
      return logout().then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
};
</script>
