<template>
  <DashboardLayout :loading="isLoadingComp" :title="pageTitle">
    <template v-slot:header-actions v-if="!isLoadingComp && computation">
      <div class="d-flex flex-column">
        <div class="text-body-2 text--secondary">
          {{ $t("computation.created") }}
        </div>
        <div style="white-space: nowrap">
          {{ formatDateTime(computation.created_at) }}
        </div>
      </div>
    </template>
    <template v-slot:breadcrumbs>
      <div class="d-flex justify-space-between mb-3">
        <router-link :to="backLink" class="text-decoration-none text-h6">
          <span class="text-h6 text--primary">←&nbsp;</span>
          <span class="font-weight-regular">{{ $t("backLink") }}</span>
        </router-link>

        <div>
          <ConfirmationDialog
            v-if="
              !state ||
              (!state.is_running &&
                (computation.status === 1 || computation.status === 2))
            "
            :callback="deleteComputation"
          >
            <template v-slot:activator="{ on }">
              <v-btn color="primary" large rounded elevation="4" v-on="on">
                {{ $t("computation.delete") }}
              </v-btn>
            </template>
          </ConfirmationDialog>
          <ConfirmationDialog
            v-if="computation.status === 2"
            :callback="unarchive"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                large
                rounded
                elevation="4"
                v-on="on"
                class="ml-4"
              >
                {{ $t("computation.unarchive") }}
              </v-btn>
            </template>
          </ConfirmationDialog>
        </div>
      </div>
    </template>
    <template v-if="!isLoadingComp && computation">
      <h2 class="text-subtitle-1">
        {{ $t("computation.uploadedFilesSection") }}
      </h2>
      <v-data-table
        :headers="headers"
        :items="files"
        class="mb-6"
        :loading="isLoadingFiles"
      >
        <template v-slot:item.uploaded="{ item }"
          >{{ formatDateTime(item.uploaded) }}
        </template>
        <template v-slot:item.size_bytes="{ item }"
          >{{ humanFileSize(item.size_bytes, true) }}
        </template>
      </v-data-table>

      <v-alert v-if="!files.length && !isValidated" dense text type="info">{{
        $t("computation.needFiles")
      }}</v-alert>

      <v-alert
        v-if="state && state.state_name === 'validation' && state.message"
        dense
        text
        :type="alertType"
      >
        {{ state.message }}
      </v-alert>
      <v-alert v-if="isValidationOutdated" dense text type="warning">{{
        $t("computation.validationOutdated")
      }}</v-alert>

      <LinearLoader
        v-if="isLoadingValidation"
        class="mb-6"
        :value="progress"
        :computation-id="id"
        :state="state"
      ></LinearLoader>
      <StageButton
        v-else
        :label="$t('computation.btn.validate')"
        :disabled="isActionDisabled || !files.length"
        :callback="validate"
      ></StageButton>

      <h2 class="text-subtitle-1 mb-4">
        {{ $t("computation.previewSection") }}
      </h2>

      <v-alert
        v-if="state && state.state_name === 'preview' && state.message"
        dense
        text
        :type="alertType"
      >
        {{ state.message }}
      </v-alert>

      <LinearLoader
        v-if="isLoadingPreview"
        class="mb-6"
        :value="progress"
        :computation-id="id"
        :state="state"
      ></LinearLoader>
      <StageButton
        v-else
        :label="$t('computation.btn.preview')"
        :disabled="!isValidated || isActionDisabled"
        :callback="preview"
      ></StageButton>

      <ComputationResult :table="previewTable"></ComputationResult>

      <h2 class="text-subtitle-1 mb-4">
        {{ $t("computation.executeSection") }}
      </h2>

      <v-alert
        v-if="state && state.state_name === 'execute' && state.message"
        dense
        text
        :type="alertType"
      >
        {{ state.message }}
      </v-alert>

      <LinearLoader
        v-if="isLoadingExecute"
        class="mb-6"
        :value="progress"
        :computation-id="id"
        :state="state"
      ></LinearLoader>
      <StageButton
        v-else
        :label="$t('computation.btn.execute')"
        :disabled="!isValidated || isActionDisabled"
        :callback="execute"
      ></StageButton>
      <ComputationResult :image="image" :table="table"></ComputationResult>

      <template v-if="isDev">
        <v-btn class="d-block" @click="checkState">Check state</v-btn>
        <div v-if="state">
          State:
          <pre>{{ state }}</pre>
        </div>
      </template>
    </template>
  </DashboardLayout>
</template>

<script>
import {
  deleteComputation,
  executeComputation,
  getComputation,
  getComputationFiles,
  getComputationImageUrl,
  getComputationState,
  getComputationTable,
  getProgress,
  previewComputation,
  unarchiveComputation,
  validateComputation,
} from "@/services/computations";
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import { dateComparator, formatDateTime, humanFileSize } from "@/utils";
import ComputationResult from "@/components/computation/ComputationResult";
import StageButton from "@/components/computation/StageButton";
import { NotFoundApiError } from "@/errors";

export default {
  name: "ComputationItem",
  components: { StageButton, ComputationResult, ConfirmationDialog },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    backLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stopFilesPolling: false,
      stopStatePolling: false,
      stopComputationPolling: false,
      isLoadingComp: true,
      isLoadingFiles: true,
      isLoadingValidationStart: false,
      isLoadingPreviewStart: false,
      isLoadingExecuteStart: false,
      computation: undefined,
      headers: [
        {
          text: "Name",
          value: "filename",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Size",
          value: "size_bytes",
        },
        {
          text: "Uploaded",
          value: "uploaded",
        },
      ],
      files: [],
      state: undefined,
      previewTable: undefined,
      image: undefined,
      table: undefined,
      progress: undefined,
    };
  },
  created() {
    this.fetchComputation()
      .then(() => {
        this.pollFiles();
        this.pollState();
        setTimeout(this.pollComputation, 2000);
      })
      .finally(() => {
        this.isLoadingComp = false;
      });
  },
  computed: {
    alertType() {
      if (this.state) {
        if (this.state.success) {
          return "success";
        } else if (this.state.success === null) {
          return "info";
        }
      }
      return "error";
    },
    pageTitle() {
      return this.computation?.name;
    },
    isDev() {
      return process.env.NODE_ENV === "development";
    },
    isActionDisabled() {
      return (
        this.isExecutionCompleted ||
        this.isLoadingValidation ||
        this.isLoadingPreview ||
        this.isLoadingExecute
      );
    },
    isLoadingValidation() {
      return (
        this.isLoadingValidationStart ||
        (this.state &&
          this.state.state_name === "validation" &&
          this.state.is_running)
      );
    },
    isLoadingPreview() {
      return (
        this.isLoadingPreviewStart ||
        (this.state &&
          this.state.state_name === "preview" &&
          this.state.is_running)
      );
    },
    isLoadingExecute() {
      return (
        this.isLoadingExecuteStart ||
        (this.state &&
          this.state.state_name === "execute" &&
          this.state.is_running)
      );
    },
    isExecutionCompleted() {
      return (
        !this.isLoadingExecute &&
        this.state?.state_name === "execute" &&
        this.state?.success
      );
    },
    isValidated() {
      return (
        !this.isLoadingValidation &&
        this.state &&
        ((this.state.state_name === "validation" && this.state.success) ||
          ["preview", "execute"].includes(this.state.state_name))
      );
    },
    isValidationOutdated() {
      let latestUpdatedFile = "";
      if (this.isValidated && this.state?.validation_start_time && this.files) {
        for (const file of this.files) {
          if (dateComparator(file.uploaded, latestUpdatedFile) === 1) {
            latestUpdatedFile = file.uploaded;
          }
        }

        return (
          dateComparator(
            latestUpdatedFile,
            this.state.validation_start_time
          ) === 1
        );
      }

      return false;
    },
  },
  watch: {
    state(state) {
      if (!state?.is_running && state?.success) {
        if (state?.preview_table_id) {
          getComputationTable(this.id, state?.preview_table_id)
            .then((response) => {
              this.previewTable = response;
            })
            .catch(() => {});
        } else {
          this.previewTable = undefined;
        }

        this.image = this.getImageUrl(this.state?.image_id);
        if (state?.table_id) {
          getComputationTable(this.id, state?.table_id)
            .then((response) => {
              this.table = response;
            })
            .catch(() => {});
        } else {
          this.table = undefined;
        }
      }
    },
  },
  methods: {
    fetchComputation() {
      return getComputation(this.id)
        .then((computation) => {
          if (
            computation.status === 2 &&
            this.$router.currentRoute.name === "computation"
          ) {
            return this.$router.push({
              name: "archiveItem",
              params: { id: this.id },
            });
          } else if (
            computation.status === 1 &&
            this.$router.currentRoute.name === "archiveItem"
          ) {
            return this.$router.push({
              name: "computation",
              params: { id: this.id },
            });
          } else if (computation.status === 3) {
            return this.$router.push({
              name: "unarchiving",
              params: { id: this.id },
            });
          }
          this.computation = computation;
        })
        .catch((error) => {
          if (error instanceof NotFoundApiError) {
            if (this.$router.currentRoute.name === "archiveItem") {
              return this.$router.push({ name: "archive" });
            } else if (this.$router.currentRoute.name === "computation") {
              return this.$router.push({ name: "computations" });
            }
          }

          //todo propagate error for logging
        });
    },
    deleteComputation() {
      return deleteComputation(this.id).then(() => {
        this.$router.push({ name: "computations" });
      });
    },
    unarchive() {
      return unarchiveComputation(this.id).then(() => {
        this.$router.push({ name: "unarchiving", params: { id: this.id } });
      });
    },
    pollComputation() {
      if (this.stopComputationPolling) {
        return Promise.resolve();
      } else {
        return this.fetchComputation()
          .then(() => {
            setTimeout(this.pollComputation, 2000);
          })
          .catch(() => {
            setTimeout(this.pollComputation, 5000);
          });
      }
    },
    getImageUrl(uid) {
      if (uid) {
        return getComputationImageUrl(this.id, uid);
      } else {
        return undefined;
      }
    },
    pollFiles() {
      const poll = () => {
        if (this.stopFilesPolling) {
          this.isLoadingFiles = false;
        } else {
          this.isLoadingFiles = true;
          return getComputationFiles(this.id)
            .then((files) => {
              this.files = files;
              this.isLoadingFiles = false;
              setTimeout(poll, 10000);
            })
            .catch(() => {
              setTimeout(poll, 3000);
            })
            .finally(() => {
              this.isLoadingFiles = false;
            });
        }
      };
      poll();
    },
    pollState() {
      if (this.stopStatePolling) {
        return Promise.resolve();
      } else {
        return this.checkState()
          .then((state) => {
            if (state.is_running) {
              setTimeout(this.pollState, 1000);
            } else {
              setTimeout(this.pollState, 3000);
            }
          })
          .catch(() => {
            setTimeout(this.pollState, 5000);
          });
      }
    },
    validate() {
      this.progress = undefined;
      this.isLoadingValidationStart = true;
      return validateComputation(this.id)
        .then(() => {
          return this.checkState();
        })
        .catch((error) => {
          this.checkState();
          throw error;
        })
        .finally(() => {
          this.isLoadingValidationStart = false;
        });
    },
    preview() {
      this.resetPreviewResults();
      this.progress = undefined;
      this.isLoadingPreviewStart = true;
      previewComputation(this.id)
        .then(() => {
          return this.checkState();
        })
        .catch((error) => {
          this.checkState();
          throw error;
        })
        .finally(() => {
          this.isLoadingPreviewStart = false;
        });
    },
    resetPreviewResults() {
      this.previewTable = undefined;
    },
    execute() {
      this.resetExecuteResults();
      this.progress = undefined;
      this.isLoadingExecuteStart = true;
      executeComputation(this.id)
        .then(() => {
          return this.checkState();
        })
        .catch((error) => {
          this.checkState();
          throw error;
        })
        .finally(() => {
          this.isLoadingExecuteStart = false;
        });
    },
    resetExecuteResults() {
      this.table = undefined;
      this.image = undefined;
    },
    checkState() {
      return getComputationState(this.id).then((response) => {
        this.state = response.state;
        if (this.state.is_running) {
          getProgress(this.id)
            .then((response) => {
              this.progress = response;
            })
            .catch(() => {});
        }

        return this.state;
      });
    },
    formatDateTime,
    humanFileSize,
  },
  beforeDestroy() {
    this.stopFilesPolling = true;
    this.stopStatePolling = true;
    this.stopComputationPolling = true;
  },
};
</script>

<style scoped></style>
