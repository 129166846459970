<template>
  <DashboardLayout :title="$t('archive.header')">
    <ComputationsList
      :loading="isLoading"
      :headers="headers"
      :items="list"
      @click:row="
        (item) => $router.push({ name: 'computation', params: { id: item.id } })
      "
    ></ComputationsList>
  </DashboardLayout>
</template>

<script>
import ComputationsList from "@/components/computation/ComputationsList";
import { getArchiveComputations } from "@/services/computations";
import ComputationsListMixin from "@/views/dashboard/computations/ComputationsListMixin";

export default {
  name: "ComputationsListPage",
  components: { ComputationsList },
  mixins: [ComputationsListMixin],
  myApiService: getArchiveComputations,
};
</script>
