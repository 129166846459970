<template>
  <AuthLayout>
    <div class="text-center">
      <div class="text-h1 font-weight-medium">404</div>
      <div class="text-h2">{{ $t("error.pageNotFound") }}</div>
    </div>
  </AuthLayout>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style></style>
